/* You can add global styles to this file, and also import other style files */
@import '~@ng-select/ng-select/themes/default.theme.css';
@import './assets/fonts/nunito-sans/nunito-sans';

:root {
  --background: #eae3d9 !important;
}

body,
body .h1,
body .h2,
body .h3,
body .h4,
body .h5,
body .h6,
h1,
h2,
h3,
h4,
h5,
h6,
html,
a,
.btn,
.ant-pagination-item,
.ngx-datatable,
.dropdown,
.ngx-datatable,
.breadcrumb,
.form-control,
.ant-tabs {
  font-family: 'Nunito Sans' !important;
}

body,
html {
  background-image: none !important;
  background-color: #f2f2f2 !important;
}

a {
  text-decoration: none;
  color: #1890ff;
}

.w-33 {
  width: calc(100% / 3);
}

.form-group {
  margin-bottom: 1rem;
}

.row.entry-row {
  margin: 8px 0px;

  [class^='col'] {
    padding: 0;
  }
}

.card .card-body {
  padding: 15px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .lp-topmenu {
    padding-top: 120px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .lp-topmenu {
    padding-top: 65px !important;
  }
}
.lp-sidebar a {
  font-size: 13px;
}

@media only screen and (min-width: 992px) and (min-width: 1200px) {
  .lp-topmenu .lp-sidebar .lp-sidebar-wrapper {
    margin: -51px auto 0 !important;
    nav {
      text-align: left !important;
      padding-left: 275px !important;
    }
  }
}

body.lp-topmenu .lp-content {
  padding: 0 24px !important;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bs-primary);
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--link-color);
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;

  #cover-spin {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(121, 121, 121, 0.65);
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  #cover-spin::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.25);
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
  }
}

.open-wrapper {
  width: 100%;

  .wrapper-content {
    float: left;
    width: calc(100% - 250px);
  }
  &.open {
    .wrapper-content {
      width: calc(100% - 60px);
    }
  }

  .sidebar-menu {
    float: left;
    width: 250px;
    height: 80vh;
    background: white;
    box-shadow: var(--shadow);

    &.open-menu {
      width: 60px !important;
      .menu-wrapper {
        span {
          display: none;
        }
      }
    }

    h4 {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 10px;
      cursor: pointer;
    }

    ul {
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        color: var(--link-color);
        list-style: none;
        margin-left: 0;

        a {
          width: 100%;
          padding: 10px;
          border-radius: 4px;
          display: block;
          color: var(--link-color);
          padding-left: 13px;

          &.active-link {
            background: rgba(0, 0, 0, 0.075);
            color: var(--brand);

            i.fa {
              color: var(--brand);
            }
          }
          &:hover {
            text-decoration: none;
          }

          i.fa {
            width: 30px;
            color: var(--link-color);
          }
        }
      }
    }
  }
  .sidebar-menu,
  .wrapper-content {
    border-radius: 4px;
    padding: 0 10px;
  }
}

.page-item {
  &.active {
    .page-link {
      background-color: var(--bs-primary);
      color: white;
      font-weight: bold;
    }
  }
  .page-link {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    border: 0;
    height: 22px;
    min-width: 24px;
    line-height: 22px;
    padding: 0 6px;
    border-radius: 3px;
    margin: 6px 3px;
    text-align: center;

    span {
      display: none;
    }
    &[aria-label='Previous'] {
      &::after {
        font-family: 'data-table' !important;
        content: '\6f';
      }
    }
    &[aria-label='Next'] {
      &::after {
        font-family: 'data-table' !important;
        content: '\70';
      }
    }
  }
}
.c-pointer {
  cursor: pointer;
}

.c-default {
  cursor: default;
}

.d-block {
  display: block;
}

.custom-tooltip {
  .tooltip-inner {
    max-width: 500px !important;
    background: rgba(0, 0, 0, 0.7);
    color: white;
  }
}

.step {
  .steps-content {
    min-height: 200px;
    padding: 15px;
    margin: 10px 0px 0px 0px !important;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    .steps-action {
      margin-top: 24px;
    }
  }
  .buttons {
    margin-top: 10px;
  }
}

.table-button {
  font-size: 18px;
  margin-right: 8px;
  text-decoration: none;
  color: var(--link-color);

  &:hover {
    color: var(--bs-primary) !important;
  }
}

abp-footer,
.flag-icon {
  display: none;
}
.c-pointer {
  cursor: pointer;
}

.badge-overlay {
  padding: 2px 6px !important;
  font-size: 72%;
  border-radius: 8px;
  font-weight: 600;

  &.success,
  .success {
    background: #28a745;
    color: white;
  }

  &.warning,
  .warning {
    background: var(--bs-warning);
    color: white;
  }

  &.danger,
  .danger {
    background: var(--bs-danger);
    color: white;
  }

  &.primary,
  .primary {
    background: var(--bs-primary);
    color: white;
  }
}

.ng-select {
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: transparent !important;
    box-shadow: 0 0 0.2rem #113059 !important;
  }
  &.ng-select-multiple {
    .ng-value {
      background: var(--bs-primary) !important;
      color: white;

      &-icon,
      &-label {
        &:hover {
          background: var(--link-color) !important;
        }
      }
    }
  }
  .ng-select-container {
    border: 2px solid #ebebeb;
    background-color: #f2f2f2;
    min-height: 30px;

    &:focus {
      background-color: #fff !important;
    }
  }
  &.input-validation-error {
    border: 2px solid;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;

    .ng-select-container {
      border-color: transparent;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

//  data table, dropdown and remove all button style
.ngx-datatable,
.ngx-datatable.material {
  background: transparent !important;

  .datatable-header {
    .datatable-header-inner {
      &:last-child {
        .datatable-row-right,
        .datatable-row-left {
          background-image: none !important;
        }
      }
      .datatable-header-cell {
        font-size: 13px;
        background: transparent !important;
        line-height: 2 !important;
        padding: 5px 5px 5px 10px !important;
      }
    }
  }

  &.bordered {
    .datatable-body-row {
      border-top: 1px solid #f8f8f8 !important;
    }
  }

  .datatable-body {
    &-cell {
      padding: 2px 5px 2px 10px !important;
      line-height: 2 !important;
      font-size: 13px !important;
    }
  }
}

.data-tables-remove-all {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 1;
}

.datatable-body-row.datatable-row-odd {
  background: #f8f8f8;
}

.datatable-checkbox input[type='checkbox']:checked:before {
  border-color: var(--bs-primary);
}

.datatable-checkbox {
  margin-top: 2px;
}

.dropdown-none-caret {
  .dropdown-toggle {
    &:after {
      border: 0;
    }
  }
}

.confirmation .confirmation-backdrop {
  background: rgba(0, 0, 0, 0.2) !important;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.2) !important;
}

.confirmation-button--approve {
  border-color: var(--bs-primary) !important;
  border-width: 2px !important;
  border-style: solid !important;
}

.confirmation-button--reject {
  background-color: transparent !important;
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  border-width: 2px !important;
  border-style: solid !important;

  &:hover {
    background-color: var(--bs-gray-300) !important;
    color: var(--bs-primary) !important;
  }
}

// ng select and x-small button style
.btn {
  &[disabled] {
    &:hover {
      background-color: var(--bs-secondary) !important;
      color: white;
      border-color: var(--bs-primary) !important;
    }
  }
  &-outline-primary {
    &:hover,
    &:active,
    &:focus {
      background-color: var(--bs-gray-300) !important;
      color: var(--bs-primary) !important;
    }
  }
  &-outline-primary[disabled] {
    background-color: var(--bs-gray-300) !important;
    color: var(--bs-primary) !important;

    &:hover {
      background-color: var(--bs-secondary) !important;
      color: var(--bs-secondary);
    }
  }
  &-warning[disabled] {
    &:hover {
      background-color: var(--warning) !important;
      color: white;
      border-color: var(--warning) !important;
    }
  }
  &-secondary[disabled] {
    &:hover {
      background-color: var(--secondary) !important;
      color: white;
      border-color: var(--secondary) !important;
    }
  }

  &.btn-xs {
    padding: 2px 8px !important;
    font-size: 10px !important;
  }
}

.custom-input {
  background-color: #fff !important;

  &:focus {
    box-shadow: none !important;
  }
}

.progress-title {
  position: absolute;
  width: 100%;
  top: -1px;
  font-size: 9px;
  font-weight: bold;
  color: var(--link-color);
  text-align: center;
}

.progress {
  background: #b9b7b7 !important;
}
.status-colors {
  .text-bg-success {
    background: #28a745 !important;
  }
}

.ant-steps-item-title {
  font-size: 13px !important;
}

.tooltip {
  font-family: 'Nunito Sans' !important;
  &.bs-tooltip {
    &-end {
      .tooltip-arrow:before {
        border-right-color: white !important;
      }
    }
    &-start {
      .tooltip-arrow:before {
        border-left-color: white !important;
      }
    }
    &-top {
      .tooltip-arrow:before {
        border-top-color: white !important;
      }
    }
    &-bottom {
      .tooltip-arrow:before {
        border-bottom-color: white !important;
      }
    }
  }
}

.outsourced-model-select .ng-option-label {
  display: block;
}

.ng-dropdown-panel {
  z-index: 1055 !important;
}

.home-page {
  .box {
    &:hover {
      background: var(--background) !important;
    }
    .icon {
      color: black;
      i {
        font-size: 36px;
      }
    }
  }
}

sai-root > abp-dynamic-layout > abp-application-layout > div.h-100 {
  max-height: calc(100vh - 105px);
  overflow-y: scroll;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  sai-root > abp-dynamic-layout > abp-application-layout > div.h-100 {
    max-height: calc(100vh - 160px);
  }
}

@media only screen and (min-width: 1366px) {
  sai-root > abp-dynamic-layout > abp-application-layout > div.h-100 {
    max-height: calc(100vh - 103px);
  }
}

abp-back-to-impersonator-nav-item {
  .menu-item-badge {
    display: inline-block !important;
    background-color: transparent !important;
    color: black !important;
  }
}

// Account => Security Logs => Refresh Button & Audit Log Refresh Button
div.mt-3.mb-lg-0.d-grid {
  padding-top: 13px;
}

// Entity Changes Refresh Button
div.col-6.d-grid.text-end {
  padding-top: 13px;
}
